import React from 'react'

import JobListing from 'components/careers/JobListing'

const SeniorFullStackDeveloper = () => (
  <JobListing
    name="Senior Full Stack developer"
    salary="$85k – $105k"
    overview={
      <>
        <p>
          We're looking for a senior-level Full Stack Developer (5+ years
          experience) to join our growing team. You'll collaborate with other
          developers and designers on challenging client projects, and report
          directly to our Co-Founder & Technical Director.
        </p>
      </>
    }
    tasks={[
      'Execute full-stack development for a variety of clients',
      'Establish and maintain best practices for development, documentation, and testing',
      'Conduct technical feasibility assessments to find efficient solutions for a given problem',
      'Work collaboratively and proactively in a multidisciplinary team environment',
      'Lead/mentor a team of developers across a project',
      'Conduct code reviews',
      'Contribute to our engineering culture',
    ]}
    qualifications={[
      '5+ years of relevant work experience',
      'Substantial experience in software engineering, including architecture and algorithms',
      'Extensive experience with React and Node.js',
      'Experience consuming and creating REST and GraphQL APIs',
      'Fluency in SQL database architecture and querying',
      'Deep know-how of Kubernetes application deployment and maintenance',
      'Experience setting up CI and deployment flows for containerized applications',
      'Experience with logging, alerting, and monitoring pipelines',
      'Excellent communication skills',
      'Resourceful and autonomous',
      'Experience writing and setting up automated testing pipelines',
      'Bonus: experience with TypeScript',
      'Bonus: mobile development experience (Native/React Native)',
    ]}
  />
)

export default SeniorFullStackDeveloper
